
/*
@keyframes orbit {
  from {
    transform: rotate(0deg) translateX(20px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(20px) rotate(-360deg);
  }
}

.atom-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  position: relative;
}

.atom-spinner-dot {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
  position: absolute;
}

.atom-spinner-dot:nth-child(1) {
  animation: orbit 1.2s linear infinite;
}

.atom-spinner-dot:nth-child(2) {
  animation: orbit 1.2s linear infinite reverse;
  animation-delay: -0.4s;
}

.atom-spinner-dot:nth-child(3) {
  animation: orbit 1.2s linear infinite;
  animation-delay: -0.8s;
}
*/



@keyframes atom-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.atom-spinner,
.atom-spinner div,
.atom-spinner div:after {
  box-sizing: border-box;
}
.atom-spinner {
  color: currentColor;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.atom-spinner div {
  transform-origin: 40px 40px;
  animation: atom-spinner 1.2s linear infinite;
}
.atom-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3.2px;
  left: 36.8px;
  width: 6.4px;
  height: 17.6px;
  border-radius: 20%;
  background: currentColor;
}
.atom-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.atom-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.atom-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.atom-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.atom-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.atom-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.atom-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.atom-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.atom-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.atom-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.atom-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.atom-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

