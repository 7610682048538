
.retrying-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white; /* Spinner color, adjust as needed */
    font-size: 24px; /* Size for the retry message */
}

.retrying-message {
    margin-top: 20px; /* Space between spinner and message */
}

